<template>
  <div class="forum-page">
    <div class="forum-page__hero">
      <div class="container">
        <div class="forum-page__logo forum-logo">
          <div
            class="forum-logo__icon"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            <svg
              width=""
              height=""
              viewBox="0 0 298 173"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M106.832 3.58114L108.616 28.3346H109.339C119.706 8.97704 134.341 0 152.93 0C176.51 0 189.095 9.67875 197.244 28.3346H197.943C212.602 5.73466 229.744 0 244.765 0C289.32 0 298 30.851 298 62.7425V172.137H240.835V76.0266C240.835 60.6132 241.534 45.5385 225.115 45.5385C207.949 45.5385 205.104 59.1614 205.104 74.9378V172.137H147.915V76.0266C147.915 60.6132 148.638 45.5385 132.195 45.5385C115.053 45.5385 112.184 59.1614 112.184 74.9378V172.137H55.7424V70.9937C41.4451 71.3566 26.0871 63.1055 26.8104 41.5944C10.729 40.5298 0 29.3992 0 8.61409V3.58114H106.832Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            class="forum-logo__text"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            Magnum <br />Marketing <br />Forum 2022
          </div>
        </div>
        <div class="forum-page__info forum-info">
          <div class="forum-info__item">
            <div
              class="forum-info__item-bold"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              Дата:
            </div>
            <div
              class="forum-info__item-text"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              10 июня
            </div>
          </div>
          <div class="forum-info__item">
            <div
              class="forum-info__item-bold"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              Начинаем
            </div>
            <div
              class="forum-info__item-text"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              в 16:00
            </div>
          </div>
          <div class="forum-info__item">
            <div
              class="forum-info__item-text"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <span>Адрес:</span>
              ТРЦ Forum,<br>пр. Сейфуллина, 617 <br />
              5 этаж, Event Space
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="forum-page__content">
      <div class="container">
        <div class="forum-page__descr">
          <div class="forum-page__partner forum-partner">
            <h2
              class="forum-partner__title"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Уважаемый партнёр!
            </h2>
            <h4
              class="forum-partner__subtitle"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Рады видеть Вас на <br />
              Magnum Marketing Forum 2022
            </h4>
          </div>
          <div class="forum-page__program forum-program">
            <h3
              class="forum-program__title"
              data-aos="fade-down"
              data-aos-duration="600"
            >
              В программе форума:
            </h3>

            <div class="forum-table">
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>16:00</span> <span>16:30</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">
                    Региcтрация гостей и фуршет
                  </div>
                  <div class="forum-table__text"></div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>16:30</span> <span>16:40</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">Открытие форума</div>
                  <div class="forum-table__text">
                    Османов Азамат Маратович, Генеральный
                    Директор ТОО «Magnum Cash&Сarry»
                  </div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>16:40</span> <span>17:00</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">
                    «Magnum x FMCG. По расчету или по любви?»
                  </div>
                  <div class="forum-table__text">
                    Мун Мария, Управляющий Директор по маркетингу ТОО
                    «Magnum Cash&Сarry»
                  </div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>17:00</span> <span>17:30</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">
                    Новая парадигма ритейл-бренда
                  </div>
                  <div class="forum-table__text">
                    Фархад Кучкаров, Директор по стратегии
                    агентства Depot WPF
                  </div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>17:30</span> <span>17:40</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">Розыгрыш подарков</div>
                  <div class="forum-table__text"></div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>17:40</span> <span>18:10</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">«Retail –новый импульс для медиа»</div>
                  <div class="forum-table__text">
                    Тишаков Вадим, Руководитель проекта RMS. Курданова Ольга, Директор по медиа и коммуникациям Magnum
                  </div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>18:10</span> <span>18:30</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">
                    «Добро пожаловать в КЛУБ!»
                  </div>
                  <div class="forum-table__text">
                    Макеев Бинур, Product owner Magnum Club
                  </div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>18:30</span> <span>18:40</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">Розыгрыш подарков</div>
                  <div class="forum-table__text"></div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>18:40</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">
                    Благотворительный аукцион
                  </div>
                  <div class="forum-table__text">
                    Аукцион картин Индиры Бадамбаевой, все собранные средства
                    будут переданы Общественному фонду помощи больным
                    онкологическими заболеваниями «Амила»
                  </div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>18:30</span> <span>20:00</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">Фуршет</div>
                  <div class="forum-table__text"></div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>19:30</span> <span>20:00</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">
                    Выступление группы «Молданазар»
                  </div>
                  <div class="forum-table__text"></div>
                </div>
              </div>
              <div
                class="forum-table__item"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <div class="forum-table__time">
                  <span>18:30</span> <span>20:00</span>
                </div>
                <div class="forum-table__content">
                  <div class="forum-table__title">Нетворкинг на террасе</div>
                  <div class="forum-table__text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="forum-partners">
      <div class="container">
        <div
          class="forum-partners__title"
          data-aos="fade-down"
          data-aos-duration="500"
        >
          Наши партнеры
        </div>
        <div class="forum-partners__inner partners-flex">
          <div class="forum-partners__item one">
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/cocacola.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/mars.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/mondelez.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/tanuki.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/bat.svg"
              alt=""
            />
          </div>
          <div class="forum-partners__item two">
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/jelzin.png"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/carl.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/almawine.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/monkey.png"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/grants.png"
              alt=""
            />
          </div>
          <div class="forum-partners__item three">
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/maxi.png"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/jacobs.png"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/rgbrands.png"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/colgate.png"
              alt=""
            />
          </div>
          <div class="forum-partners__item four">
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/heineken.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/tiger.svg"
              alt=""
            />
            <img
              class="forum-partners__img"
              src="@/assets/img/forum/little-brazil.svg"
              alt=""
            />
          </div>
        </div>

      <div class="forum-partners__inner partners-grid">
        <img
          class="forum-partners__img one"
          src="@/assets/img/forum/cocacola.svg"
          alt=""
        />
        <img
          class="forum-partners__img two"
          src="@/assets/img/forum/mars.svg"
          alt=""
        />
        <img
          class="forum-partners__img three"
          src="@/assets/img/forum/mondelez.svg"
          alt=""
        />
        <img
          class="forum-partners__img four"
          src="@/assets/img/forum/tanuki.svg"
          alt=""
        />
        <img
          class="forum-partners__img five"
          src="@/assets/img/forum/bat.svg"
          alt=""
        />
        <img
          class="forum-partners__img six"
          src="@/assets/img/forum/jelzin.png"
          alt=""
        />
        <img
          class="forum-partners__img seven"
          src="@/assets/img/forum/carl.svg"
          alt=""
        />
        <img
          class="forum-partners__img eight"
          src="@/assets/img/forum/almawine.svg"
          alt=""
        />
        <img
          class="forum-partners__img nine"
          src="@/assets/img/forum/monkey.png"
          alt=""
        />
        <img
          class="forum-partners__img ten"
          src="@/assets/img/forum/grants.png"
          alt=""
        />
        <img
          class="forum-partners__img eleven"
          src="@/assets/img/forum/maxi.png"
          alt=""
        />
        <img
          class="forum-partners__img twelve"
          src="@/assets/img/forum/jacobs.png"
          alt=""
        />
        <img
          class="forum-partners__img thirteen"
          src="@/assets/img/forum/rgbrands.png"
          alt=""
        />
        <img
          class="forum-partners__img fourteen"
          src="@/assets/img/forum/colgate.png"
          alt=""
        />
        <img
          class="forum-partners__img fifteen"
          src="@/assets/img/forum/heineken.svg"
          alt=""
        />
        <img
          class="forum-partners__img sixteen"
          src="@/assets/img/forum/tiger.svg"
          alt=""
        />
        <img
          class="forum-partners__img seventeen"
          src="@/assets/img/forum/little-brazil.svg"
          alt=""
        />
      </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forum",
};
</script>
